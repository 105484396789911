import React, { useState, useEffect, useContext } from "react";
import { Fade } from "react-reveal";
import ResponsiveContainer from "components/UI/ResponsiveContainer";
import ButtonProfile from "./ButtonProfile";
import ButtonContact from "./ButtonContact";
import { IMGNoPhoto } from "components/images";
import LanguageContext from "components/ctx/language-context";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaChartBar } from "react-icons/fa";
import PeluangInvestasiSlideItem from "./PeluangInvestasiSlideItem";

const SektorUnggulan = (props) => {
  const [first, setfirst] = useState(null);
  const langCtx = useContext(LanguageContext);

  useEffect(() => {
    setfirst(
      props.dataSekUng?.dataSektor != null && props.dataSekUng?.dataSektor[0]
    );
  }, [props.dataSekUng]);

  const update = (data) => {
    setfirst(data);
  };
  return (
    <div className="bg-bkpm-light-blue-02 shadow-xl mt-5">
      <ResponsiveContainer>
        <Fade bottom spy={props.activeProvince} distance="10px" cascade={false}>
          <div className="grid grid-cols-5 gap-8 py-10">
            <div className="md:col-span-2 col-span-5">
              <h3 className="text-bkpm-dark-blue-01 font-semibold remove-mb">
                {langCtx.language == "id"
                  ? `Sektor Unggulan`
                  : `Leading Sector`}
              </h3>
              <h5 className="text-bkpm-dark-blue-01 font-medium">
                ({props.mapData?.name})
              </h5>

              <div className="flex flex-wrap items-center">
                {props.dataSekUng?.dataSektor?.map((v, i) => {
                  return (
                    <div
                      className="w-3/12 cursor-pointer hover:shadow-lg p-3 rounded-lg"
                      key={i}
                      onClick={() => update(v)}
                      onMouseOver={() => props.setIsPlay(false)}
                      onMouseOut={() => props.setIsPlay(true)}
                    >
                      <img
                        src={v.icon}
                        className="mx-auto"
                        width={60}
                        height={60}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = IMGNoPhoto;
                        }}
                        alt={`icon-${v.name}`}
                      />
                      <p className="text-center text-xs font-semibold text-bkpm-dark-blue-01 mt-2">
                        {v.name.toUpperCase()}
                      </p>
                    </div>
                  );
                })}
              </div>

              <div className="flex items-center gap-5 md:mt-30 mt-10">
                <ButtonContact
                  mainTitle={props.mainTitle}
                  mapData={props.mapData}
                />
                <ButtonProfile
                  mainTitle={props.mainTitle}
                  mapData={props.mapData}
                />
              </div>
            </div>
            <div className="md:col-span-3 col-span-5">
              <div
                className="text-md text-justify mt-5"
                dangerouslySetInnerHTML={{ __html: first?.desc }}
              />
              <p className="text-green-600 font-bold text-xs">
                {first?.sumber && (
                  <>
                    {langCtx.language == "id" ? `Sumber` : `Source`} :{" "}
                    {first?.sumber}
                  </>
                )}
              </p>
              <div className="mt-5">
                <h5 className="text-bkpm-dark-blue-01 font-semibold flex items-center gap-4">
                  <FaChartBar color="text-bkpm-dark-blue-01" size={25} />
                  Peluang Investasi
                </h5>
                <Fade bottom distance="30px">
                  <Swiper
                    spaceBetween={10}
                    slidesPerView={2}
                    autoplay={true}
                    // loop={true}
                    // breakpoints={{
                    //   480: {
                    //     slidesPerView: 2.3,
                    //   },
                    //   786: {
                    //     slidesPerView: 3.3,
                    //   },
                    //   980: {
                    //     slidesPerView: 3.3,
                    //   },
                    // }}
                  >
                    {props.dataSekUng?.peluangInvestasi?.map((v, i) => {
                      return (
                        <SwiperSlide key={i}>
                          <PeluangInvestasiSlideItem
                            labelPrioritas={v.labelPrioritas}
                            title={v.nama}
                            area={v.kabkotNama || v.provinsiNama}
                            tahun={v.tahun}
                            value={`Rp ${v.nilaiInvestasi}`}
                            image={v.image}
                            idPeluang={v.idPeluangKabkot || v.idPeluangProvinsi}
                          />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Fade>
              </div>
            </div>
          </div>
        </Fade>
      </ResponsiveContainer>
    </div>
  );
};

export default SektorUnggulan;
