import React, { Fragment } from "react"

import IcJumlahPenduduk from "components/images/ic-jumlah-penduduk.svg"
import IcKawasanIndustri from "components/images/ic-kawasan-industri.svg"
import IcPDRB from "components/images/ic-pdrb.svg"
import IcPeluang from "components/images/ic-peluang.svg"
import IcRealisasi from "components/images/ic-realisasi.svg"
import IcUMR from "components/images/ic-umr.svg"

import ItemPeluang from "./ItemPeluang"

const DataList = ({ mainTitle, dataPropinsi }) => {
  return (
    <div className="mb-8 grid grid-cols-1 lg:grid-cols-2">
      {dataPropinsi &&
        dataPropinsi.map((item, index) => {
          return (
            <Fragment key={index}>
              {item.label.includes(`${mainTitle.titlePeluang}`) && (
                <ItemPeluang
                  key={new Date().getMilliseconds()}
                  icon={IcPeluang}
                  title={item.label}
                  value={`${item.value}`}
                />
              )}
              {item.label.includes(`${mainTitle.titleKawasanIndustri}`) && (
                <ItemPeluang
                  key={new Date().getMilliseconds()}
                  icon={IcKawasanIndustri}
                  title={item.label}
                  value={`${item.value}`}
                />
              )}
              {item.label.includes(`${mainTitle.titleUmr}`) && (
                <ItemPeluang
                  key={new Date().getMilliseconds()}
                  icon={IcUMR}
                  title={item.label}
                  value={`${item.value}`}
                />
              )}
              {item.label.includes(`${mainTitle.titleRealisasiInvestasi}`) && (
                <ItemPeluang
                  key={new Date().getMilliseconds()}
                  icon={IcRealisasi}
                  title={item.label}
                  value={[`${item.detail.PMA}`, <br />, `${item.detail.PMDN}`]}
                />
              )}
              {item.label.includes(`${mainTitle.titleJumlahPenduduk}`) && (
                <ItemPeluang
                  key={new Date().getMilliseconds()}
                  icon={IcJumlahPenduduk}
                  title={item.label}
                  value={`${item.value}`}
                />
              )}
              {item.label.includes(`${mainTitle.titlePdrb}`) && (
                <ItemPeluang
                  key={new Date().getMilliseconds()}
                  icon={IcPDRB}
                  title={item.label}
                  value={item.value}
                />
              )}
            </Fragment>
          )
        })}
    </div>
  )
}

export default DataList
