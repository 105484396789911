import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { IoPricetag } from "react-icons/io5"
import { BiCalendar } from "react-icons/bi"
import { Card, CardBody } from "@windmill/react-ui"
import { FiMapPin } from "react-icons/fi"
import { IMGNoPhoto } from "components/images"

const PeluangInvestasiSlideItem = props => {
  const [linkPeluang, setLinkPeluang] = useState("detail")
  useEffect(() => {
    setLinkPeluang(props.labelPrioritas ? "detail" : "daerah")
  }, [props])
  return (
    <Card className="h-full border-solid border-[1px] border-gray-200">
      {props.labelPrioritas ? (
        <div
          style={{
            height: "7.5rem",
            position: "absolute",
            top: "-0.375rem",
            overflow: "hidden",
            right: "-0.375rem",
            width: "7.5rem",
          }}
        >
          <span
            style={{
              backgroundColor: "#00f",
              WebkitBackfaceVisibility: "hidden",
              backfaceVisibility: "hidden",
              color: "#fff",
              fontSize: ".625rem",
              fontWeight: 600,
              textTransform: "uppercase",
              textAlign: "center",
              WebkitTransform: "rotate(45deg)",
              transform: "rotate(45deg)",
              WebkitTransformOrigin: "center",
              transformOrigin: "center",
              WebkitTransformStyle: "preserve-3d",
              padding: "0.5rem 2rem",
              position: "absolute",
              zIndex: 2,
              width: "200%",
              display: "block",
              top: "1.25rem",
              right: "-5rem",
            }}
          >
            {props.labelPrioritas}
          </span>
        </div>
      ) : null}
      <Link to={`/peluang-investasi/${linkPeluang}/?id=${props.idPeluang}`}>
        <img
          src={props.image || ""}
          alt=""
          className="w-full rounded-t-md"
          style={{ width: "500px", height: "250px", objectFit: "cover" }}
          onError={e => {
            e.target.onerror = null
            e.target.src = IMGNoPhoto
          }}
        />
      </Link>
      <CardBody className="p-3">
        <Link
          to={`/peluang-investasi/${linkPeluang}/?id=${props.idPeluang}`}
          className="font-semibold text-lg inline-block hover:text-bkpm-light-blue-01 transition duration-500 ease-in-out line-clamp-1"
        >
          {props.title}
        </Link>

        <Link to={`/peluang-investasi/${linkPeluang}/?id=${props.idPeluang}`}>
          <p className="text-gray-400 text-xs">
            <FiMapPin className="inline text-bkpm-dark-blue-01" />
            &nbsp;&nbsp;{props.area}
          </p>
        </Link>

        <Link to={`/peluang-investasi/${linkPeluang}/?id=${props.idPeluang}`}>
          <div style={{ fontSize: 12 }} className="inline-block">
            <IoPricetag className="inline" />
            &nbsp;&nbsp;{props.value}&emsp;
          </div>
          <div style={{ fontSize: 12 }} className="inline-block">
            <BiCalendar className="inline" />
            &nbsp;&nbsp;{props.tahun}
          </div>
        </Link>
      </CardBody>
    </Card>
  )
}

export default PeluangInvestasiSlideItem
