import React from "react"
import Fade from "react-reveal/Fade"
import { Link } from "gatsby"

import Accordion from "./Accordion"

const Insentif = ({ accordionState, setAccordionState, mainTitle }) => {
  const clickHandler = i => {
    setAccordionState(prevAccordion => {
      return prevAccordion.map((item, index) => {
        let isOpen = false
        if (index == i) {
          isOpen = true
        }
        return { ...item, isOpen: isOpen }
      })
    })
  }

  return (
    <Fade bottom distance="50px">
      <div>
        <div className="bg-white text-bkpm-dark-blue-01 rounded-lg p-6 shadow-xl border border-solid border-gray-200 md:h-full">
          <h2 className="mb-8 text-bkpm-green">{mainTitle.titleInsentif}</h2>
          {accordionState.map((content, index) => {
            return (
              <Accordion
                title={content.title}
                isOpen={content.isOpen}
                key={index}
                clickHandler={() => clickHandler(index)}
              >
                <div
                  dangerouslySetInnerHTML={{__html: content.text+"..."}}
                />
                <Link
                  to="/insentif"
                  state={{ defaultTab: content.tab }}
                  className="font-bold uppercase"
                >
                  {content.labelDetil}
                </Link>
              </Accordion>
            )
          })}
        </div>
      </div>
    </Fade>
  )
}

export default Insentif
