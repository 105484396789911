import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { IMGNoPhoto } from "components/images"

const LogoIcon = styled.img`
  max-height: 80px;
`
const LabelContainer = styled.div`
  max-width: 150px;
  text-align: center;
`

const PartnerIcon = props => {
  return (
    <div className="w-1/2 px-4 text-center mb-6 sm:w-auto">
      <div className="mb-3">
        <Link to={props.to} target={"_blank"} className="font-bold uppercase">
          <LogoIcon
            src={props.image}
            title={props.title}
            className="w-3/4 mx-auto sm:w-auto"
            onError={e => {
              e.target.onerror = null
              e.target.src = IMGNoPhoto
            }}
          />
        </Link>
      </div>
      <LabelContainer className="mx-auto">
        <h6>{props.label}</h6>
      </LabelContainer>
    </div>
  )
}

export default PartnerIcon
