import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "components/Layout/Layout";
import Slider from "components/Page/Home/Slider";
import Intro from "components/Page/Home/Intro";
import MapData from "components/Page/Home/MapData";
import PeluangProyek from "components/Page/Home/PeluangProyek";
import Partner from "components/Page/Home/Partner";
import Sektor from "components/Page/Home/Sektor";
import Ikn from "components/Page/Home/Ikn";
import LanguageContext from "components/ctx/language-context";
import LoadingBar from "components/UI/LoadingBar";
import Footer from "components/Layout/Footer";
import KawasanIndustriKek from "components/Page/Home/KawasanIndustriKek";

const DataHome = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            apiUrl
          }
        }
      }
    `
  );

  const [titleHome, setTitleHome] = useState(null);
  const [accordionState, setAccordionState] = useState(null);
  const [dataKawasanIndustri, setDataKawasanIndustri] = useState(null);
  const [dataRegional, setDataRegional] = useState(null);
  const [dataSlider, setDataSlider] = useState(null);
  const [dataPeluangProyek, setDataPeluangProyek] = useState(null);
  const [dataPeluangInvestasi, setDataPeluangInvestasi] = useState(null);
  const [dataSliderPeluangs, setDataSliderPeluangs] = useState(null);
  const [dataDeskripsiPeluangs, setDataDeskripsiPeluangs] = useState(null);
  const [dataActivePeluangArea, setActivePeluangArea] = useState(null);
  const [dataSektorNasional, setDataSektorNasional] = useState([]);
  const [dataSektorDaerah, setDataSektorDaerah] = useState(null);
  const [dataIkn, setDataIkn] = useState(null);
  const [dataKawasanIndustriKek, setDataKawasanIndustriKek] = useState(null);
  const [dataKawasanIndustriBlok, setDataKawasanIndustriBlok] = useState(null);
  const [dataKawasanIndustriPeluangKek, setDataKawasanIndustriPeluangKek] =
    useState(null);
  const [dataKawasanIndustriPeluangBlok, setDataKawasanIndustriPeluangBlok] =
    useState(null);
  const [loader, setLoader] = useState(false);

  const langCtx = useContext(LanguageContext);

  useEffect(async () => {
    const url = process.env.APIURL + "api/FrontEnd/GetHome";
    const result = await axios.get(url, {
      headers: {
        "X-localization": langCtx.language,
      },
    });
    setLoader(true);
    setTitleHome({ ...result.data.Data.titleHome });
    setAccordionState([
      ...result.data.Data.insentifs.map((item, index) => {
        return {
          labelDetil: item.labelDetil,
          title: item.title,
          text: item.deskripsi,
          tab: item.tab,
          isOpen: index === 0 ? true : false,
        };
      }),
    ]);
    setActivePeluangArea(result.data.Data.peluangInvestasi[0].title);
    setDataKawasanIndustri({ ...result.data.Data.kawasan });
    setDataRegional({ ...result.data.Data.regional });
    setDataSlider([...result.data.Data.sliders]);
    setDataPeluangProyek([...result.data.Data.peluangProyek]);
    setDataPeluangInvestasi([...result.data.Data.peluangInvestasi]);
    setDataSliderPeluangs([...result.data.Data.sliderPeluangs]);
    setDataDeskripsiPeluangs([...result.data.Data.deskripsiSingkatPeluangs]);
  }, [langCtx]);

  useEffect(async () => {
    const url = process.env.APIURLV2 + "api/Webs/GetJsonSektorNasional";
    const result = await axios.get(url, {
      headers: {
        xLanguange: langCtx.language,
      },
    });
    if (result.data.isSuccess) setDataSektorNasional([...result.data.data]);
  }, [langCtx]);

  useEffect(async () => {
    const url = process.env.APIURLV2 + "api/Webs/GetHomeSektorDaerahAll";
    const result = await axios.get(url, {
      headers: {
        xLanguange: langCtx.language,
      },
    });
    if (result.data.isSuccess) setDataSektorDaerah([...result.data.data]);
  }, [langCtx]);

  useEffect(async () => {
    const url = process.env.APIURLV2 + "api/Webs/GetWebHomeIkn";
    const result = await axios.get(url, {
      headers: {
        xLanguange: langCtx.language,
      },
    });
    if (result.data.isSuccess) setDataIkn(result.data.data);
  }, [langCtx]);
  useEffect(async () => {
    const url = process.env.APIURLV2 + "api/Webs/GetKawasanIndustriKek";
    const result = await axios.get(url, {
      headers: {
        xLanguange: langCtx.language,
      },
    });
    if (result.data.isSuccess) setDataKawasanIndustriKek(result.data.data);
  }, [langCtx]);
  useEffect(async () => {
    const url = process.env.APIURLV2 + "api/Webs/GetKawasanIndustriBlok";
    const result = await axios.get(url, {
      headers: {
        xLanguange: langCtx.language,
      },
    });
    if (result.data.isSuccess) setDataKawasanIndustriBlok(result.data.data);
  }, [langCtx]);
  useEffect(async () => {
    const url =
      process.env.APIURLV2 + "api/Webs/GetKawasanIndustriPeluangs?tipe=2";
    const result = await axios.get(url, {
      headers: {
        xLanguange: langCtx.language,
      },
    });
    if (result.data.isSuccess)
      setDataKawasanIndustriPeluangBlok(result.data.data);
  }, [langCtx]);
  useEffect(async () => {
    const url =
      process.env.APIURLV2 + "api/Webs/GetKawasanIndustriPeluangs?tipe=1";
    const result = await axios.get(url, {
      headers: {
        xLanguange: langCtx.language,
      },
    });
    if (result.data.isSuccess)
      setDataKawasanIndustriPeluangKek(result.data.data);
  }, [langCtx]);

  return loader ? (
    <>
      {dataSlider && <Slider dataSlider={dataSlider} />}
      {accordionState && (
        <Intro
          mainTitle={titleHome}
          accordionState={accordionState}
          setAccordionState={setAccordionState}
        />
      )}

      {dataIkn && <Ikn data={dataIkn} />}

      {dataPeluangProyek && (
        <PeluangProyek
          mainTitle={titleHome}
          dataPeluangProyek={dataPeluangProyek}
          dataPeluangInvestasi={dataPeluangInvestasi}
          langCtx={langCtx}
          dataSliderPeluangs={dataSliderPeluangs}
          dataDeskripsiPeluangs={dataDeskripsiPeluangs}
          dataActivePeluangArea={dataActivePeluangArea}
        />
      )}

      {dataSektorNasional.length !== 0 && (
        <Sektor dataSektorNasional={dataSektorNasional} />
      )}

      {dataRegional && dataSektorDaerah && (
        <MapData
          mainTitle={titleHome}
          dataRegional={dataRegional}
          dataSektorDaerah={dataSektorDaerah}
        />
      )}

      {dataKawasanIndustriKek && dataKawasanIndustriBlok && (
        <KawasanIndustriKek
          dataKek={dataKawasanIndustriKek}
          dataBlok={dataKawasanIndustriBlok}
          dataPeluangKek={dataKawasanIndustriPeluangKek}
          dataPeluangBlok={dataKawasanIndustriPeluangBlok}
          langCtx={langCtx}
        />
      )}

      <Partner mainTitle={titleHome} />
      <Footer />
    </>
  ) : (
    <LoadingBar />
  );
};

const Home = () => {
  return (
    <Layout pageID="home">
      <DataHome />
    </Layout>
  );
};

export default Home;
