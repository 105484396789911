import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { Fade } from "react-reveal"
import ArrowButton from "components/UI/ArrowButton"
import ResponsiveContainer from "components/UI/ResponsiveContainer"
import { IMGNoPhoto } from "components/images"
import LanguageContext from "components/ctx/language-context"

const Sektor = props => {
  const langCtx = useContext(LanguageContext)
  const [activeSektorId, setActiveSektorId] = useState(1)
  const [dataSektor, setDataSektor] = useState(null)
  const [isPlay, setIsPlay] = useState(true)

  useEffect(() => {
    if (props?.dataSektorNasional?.length !== 0) {
      const activeSektorItem = props?.dataSektorNasional.find(
        item => item.idSektorNasional === activeSektorId
      )
      setDataSektor(activeSektorItem)
    }
  }, [activeSektorId])

  useEffect(() => {
    let array = props?.dataSektorNasional,
      intervalDurationMs = 10000,
      currentIndex = 0,
      maxNumTimes = -1,
      numTimesRan = 0,
      interval

    if (isPlay) {
      interval = setInterval(function () {
        if (maxNumTimes !== 0) {
          setActiveSektorId(array[currentIndex].idSektorNasional)
          currentIndex++
          if (currentIndex > array.length - 1) {
            if (maxNumTimes === -1) {
              currentIndex = 0
            } else {
              numTimesRan++
              if (numTimesRan === maxNumTimes) {
                clearInterval(interval)
              } else {
                currentIndex = 0
              }
            }
          }
        } else {
          clearInterval(interval)
        }
      }, intervalDurationMs)
    }
    return () => {
      clearInterval(interval)
    }
  }, [isPlay])
  return (
    <section className="bg-white my-5 py-5">
      <ResponsiveContainer>
        <h3 className="text-bkpm-dark-blue-01 font-bold">
          {langCtx.language == "id"
            ? `SEKTOR UNGGULAN NASIONAL`
            : `NATIONAL LEADING SECTOR`}
        </h3>
        <div className="flex justify-between mt-10">
          <div
            className="w-8/12"
            onMouseOver={() => setIsPlay(false)}
            onMouseOut={() => setIsPlay(true)}
          >
            <Fade bottom spy={activeSektorId} distance="10px" cascade={false}>
              <div className="flex items-center">
                <div className="pr-6 border-r-[3px] mr-6 border-bkpm-dark-blue-01">
                  <img
                    src={`${dataSektor?.fileIcon}`}
                    className="w-20"
                    alt={dataSektor?.nama}
                    onError={e => {
                      e.target.onerror = null
                      e.target.src = IMGNoPhoto
                    }}
                  />
                </div>
                <div>
                  <p className="text-xl font-bold remove-mb">
                    {langCtx.language == "id" ? `SEKTOR` : `SECTOR`}
                  </p>
                  <p className="text-xl font-bold remove-mb">
                    {dataSektor?.nama}
                  </p>
                </div>
              </div>
              <div
                className="text-md text-justify mt-5"
                dangerouslySetInnerHTML={{
                  __html: dataSektor?.deskripsiSingkat,
                }}
              />
              <div className="flex justify-end my-8">
                <ArrowButton
                  buttonStyle="expanded"
                  colorVar="green"
                  buttonClick={() =>
                    navigate(`/sektor/?id=${dataSektor.idSektorNasional}`)
                  }
                >
                  {langCtx.language == "id" ? `Selengkapnya` : `More`}
                </ArrowButton>
              </div>
              <div className="flex justify-between md:gap-1 flex-wrap md:flex-nowrap mt-40">
                {dataSektor?.paramIndexes?.map((v, i) => {
                  return (
                    <div className="w-6/12 md:w-auto" key={i}>
                      <h3 className="text-bkpm-dark-blue-01 text-center font-semibold min-h-[40px]">
                        {v.numericValue}{" "}
                        <span className="font-semibold text-bkpm-dark-blue-01 text-sm">
                          {v.satuan}
                        </span>
                      </h3>
                      <p className="text-bkpm-dark-blue-01 text-center text-sm remove-mb mx-2">
                        {v.nama}
                      </p>
                      <p className="text-bkpm-dark-blue-01 text-center text-sm">
                        ({v.tahun})
                      </p>
                    </div>
                  )
                })}
              </div>
            </Fade>
          </div>
          <div className="w-3/12">
            <div className="flex flex-wrap items-center">
              {props?.dataSektorNasional.map((v, i) => {
                return (
                  <div
                    key={i}
                    className={`w-6/12 mb-5 hover:cursor-pointer hover:shadow-lg p-2 rounded-lg ${
                      activeSektorId === v.idSektorNasional && "shadow-lg"
                    }`}
                    onClick={() => setActiveSektorId(v.idSektorNasional)}
                  >
                    <img
                      src={`${v.fileIcon}`}
                      className="w-16 mx-auto"
                      alt={v.nama}
                      onError={e => {
                        e.target.onerror = null
                        e.target.src = IMGNoPhoto
                      }}
                    />
                    <p className="text-center text-sm text-gray-500-600 mt-2 hidden md:block">
                      {v.nama}
                    </p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </ResponsiveContainer>
    </section>
  )
}

// const Sektor = () => {
//   return (
//     <section className="bg-white my-5 py-5">
//       <ResponsiveContainer>
//         <h3 className="text-bkpm-dark-blue-01 font-bold">
//           SEKTOR UNGGULAN NASIONAL
//         </h3>
//         <div className="flex justify-between mt-10">
//           <div className="w-8/12">
//             <div className="flex items-center">
//               <div className="pr-3 border-r-4 mr-3 border-yellow-300">
//                 <img src="https://user-images.githubusercontent.com/49223890/201007946-ee408bbf-9a9f-4c4d-a70b-469075ec7ef6.png" />
//               </div>
//               <div>
//                 <p className="text-yellow-300 text-xl font-bold remove-mb">
//                   Sektor
//                 </p>
//                 <p className="text-yellow-300 text-xl font-bold remove-mb">
//                   Pengangkutan
//                 </p>
//               </div>
//             </div>
//             <p className="text-md text-justify mt-5">
//               Program pengembangan produk yang berorientasi ekspor dan upaya
//               peningkatan nilai tambah produk yang sudah ada dengan pola
//               pengembangan korporasi serta food estate pada sektor pertanian,
//               membutuhkan investasi yang terarah dan terukur, dan itu terbuka
//               bagi semua kalangan.
//             </p>
//             <p className="text-green-600 font-bold text-xs">
//               Sumber : Kementerian Pertanian RI
//             </p>
//             <div className="flex justify-end mb-8">
//               <ArrowButton buttonStyle="expanded" colorVar="green">
//                 Selengkapnya
//               </ArrowButton>
//             </div>
//             <div className="flex">
//               <div className="w-3/12">
//                 <h1 className="text-bkpm-dark-blue-01 text-center font-semibold min-h-[80px]">
//                   3.927 T (IDR)
//                 </h1>
//                 <p className="text-bkpm-dark-blue-01 text-center remove-mb mx-2">
//                   Nilai Produksi
//                 </p>
//                 <p className="text-bkpm-dark-blue-01 text-center">(2021)</p>
//               </div>
//               <div className="w-3/12">
//                 <h1 className="text-bkpm-dark-blue-01 text-center font-semibold min-h-[80px]">
//                   9,85%
//                 </h1>
//                 <p className="text-bkpm-dark-blue-01 text-center remove-mb mx-2">
//                   Kontribusi terhadap PDB
//                 </p>
//                 <p className="text-bkpm-dark-blue-01 text-center">(2021)</p>
//               </div>
//               <div className="w-3/12">
//                 <h1 className="text-bkpm-dark-blue-01 text-center font-semibold min-h-[80px]">
//                   7,65%
//                 </h1>
//                 <p className="text-bkpm-dark-blue-01 text-center remove-mb mx-2">
//                   Share Ekspor terhadap PDB
//                 </p>
//                 <p className="text-bkpm-dark-blue-01 text-center">(2021)</p>
//               </div>
//               <div className="w-3/12">
//                 <h1 className="text-bkpm-dark-blue-01 text-center font-semibold min-h-[80px]">
//                   1.520 Juta (USD)
//                 </h1>
//                 <p className="text-bkpm-dark-blue-01 text-center remove-mb mx-2">
//                   FDI
//                 </p>
//                 <p className="text-bkpm-dark-blue-01 text-center">(2021)</p>
//               </div>
//             </div>
//           </div>
//           <div className="w-3/12">
//             <div className="flex flex-wrap items-center">
//               <div className="w-6/12 mb-5">
//                 <img src="/images/ic-pertanian.png" className="mx-auto" />
//                 <p className="text-center text-sm font-semibold text-green-600 mt-2">
//                   PERTANIAN
//                 </p>
//               </div>
//               <div className="w-6/12 mb-5">
//                 <img src="/images/ic-pertambangan.png" className="mx-auto" />
//                 <p className="text-center text-sm font-semibold text-orange-500 mt-2">
//                   PERTAMBANGAN
//                 </p>
//               </div>
//               <div className="w-6/12 mb-5">
//                 <img src="/images/ic-perindustrian.png" className="mx-auto" />
//                 <p className="text-center text-sm font-semibold text-red-500 mt-2">
//                   PERINDUSTRIAN
//                 </p>
//               </div>
//               <div className="w-6/12 mb-5">
//                 <img src="/images/ic-perikanan.png" className="mx-auto" />
//                 <p className="text-center text-sm font-semibold text-blue-500 mt-2">
//                   PERIKANAN
//                 </p>
//               </div>
//               <div className="w-6/12 mb-5">
//                 <img src="/images/ic-perdagangan.png" className="mx-auto" />
//                 <p className="text-center text-sm font-semibold text-yellow-800 mt-2">
//                   PERDAGANGAN
//                 </p>
//               </div>
//               <div className="w-6/12 mb-5">
//                 <img src="/images/ic-pengangkutan.png" className="mx-auto" />
//                 <p className="text-center text-sm font-semibold text-yellow-500 mt-2">
//                   PENGANGKUTAN
//                 </p>
//               </div>
//               <div className="w-6/12 mb-5">
//                 <img src="/images/ic-pariwisata.png" className="mx-auto" />
//                 <p className="text-center text-sm font-semibold text-[#7E9B46] mt-2">
//                   PARIWISATA
//                 </p>
//               </div>
//               <div className="w-6/12 mb-5">
//                 <img src="/images/ic-konstruksi.png" className="mx-auto" />
//                 <p className="text-center text-sm font-semibold text-[#8E9092] mt-2">
//                   KONSTRUKSI
//                 </p>
//               </div>
//               <div className="w-6/12 mb-5">
//                 <img src="/images/ic-keuangan.png" className="mx-auto" />
//                 <p className="text-center text-sm font-semibold text-[#EC4061] mt-2">
//                   KEUANGAN
//                 </p>
//               </div>
//               <div className="w-6/12 mb-5">
//                 <img src="/images/ic-energi.png" className="mx-auto" />
//                 <p className="text-center text-sm font-semibold text-[#A34398] mt-2">
//                   ENERGI
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </ResponsiveContainer>
//     </section>
//   )
// }

export default Sektor
