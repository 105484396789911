import React from "react"

import ResponsiveContainer from "components/UI/ResponsiveContainer"

import VideoContainer from "./VideoContainer"
import Insentif from "./Insentif"

const Index = ({ accordionState, setAccordionState, mainTitle }) => {
  return (
    <section className="relative mt-16">
      <ResponsiveContainer className="pb-16">
        <div className="grid grid-cols-1 gap-8 -mt-12 md:grid-cols-2">
          <VideoContainer />
          <Insentif
            mainTitle={mainTitle}
            accordionState={accordionState}
            setAccordionState={setAccordionState}
          />
        </div>
      </ResponsiveContainer>
    </section>
  )
}

export default Index
