import React from "react"
import { navigate } from "gatsby"

import ArrowButton from "components/UI/ArrowButton"

const ButtonProfile = ({ mainTitle, mapData }) => {
  return (
    <div className="mb-8">
      <ArrowButton
        buttonStyle="expanded"
        colorVar="green"
        buttonClick={() => navigate(`/daerah/?id=${mapData.id}`)}
      >
        {mainTitle.labelBtnDetailProfile}
      </ArrowButton>
    </div>
  )
}

export default ButtonProfile
