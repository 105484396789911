import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import { BiPlayCircle } from "react-icons/bi"
import Modal from "react-modal"
import { Player } from "video-react"
import "video-react/dist/video-react.css"

Modal.setAppElement("#___gatsby")

const VideoContainer = () => {
  const [videoModalIsOpen, setVideoModalIsOpen] = useState(false)
  const openModal = () => setVideoModalIsOpen(true)
  const closeModal = () => setVideoModalIsOpen(false)

  return (
    <>
      <Modal
        isOpen={videoModalIsOpen}
        onRequestClose={closeModal}
        overlayClassName="inset-0 bg-opacity-90 bg-black z-50 fixed flex items-center justify-center "
        className="p-0 w-10/12"
        style={{ content: { maxWidth: "650px" } }}
        contentLabel="Youtube Video"
      >
        <Player fluid={true} autoPlay={true} width="100%">
          <source src="/pir/pir_video.mp4" />
        </Player>
      </Modal>
      <Fade bottom distance="50px">
        <div>
          <div className="bg-black bg-center bg-cover rounded-lg p-6 shadow-xl h-60 flex items-center justify-center md:h-96">
            <Player fluid={true} autoPlay={true} muted={true} width="100%">
              <source src="/pir/pir_video.mp4" />
            </Player>
            {/*  <button
              className="text-white text-7xl"
              type="button"
              aria-label="button"
              onClick={openModal}
            >
              <BiPlayCircle />
            </button> */}
          </div>
        </div>
      </Fade>
    </>
  )
}

export default VideoContainer
