import React, { useEffect } from "react"
import styled from "styled-components"

const SVGMap = props => {
  useEffect(() => {
    let array = props.map,
      intervalDurationMs = 5000,
      currentIndex = 0,
      maxNumTimes = -1,
      numTimesRan = 0,
      interval

    if (props.isPlay) {
      interval = setInterval(function () {
        if (maxNumTimes !== 0) {
          props.setActiveProvince(array[currentIndex].id)
          currentIndex++
          if (currentIndex > array.length - 1) {
            if (maxNumTimes === -1) {
              currentIndex = 0
            } else {
              numTimesRan++
              if (numTimesRan === maxNumTimes) {
                clearInterval(interval)
              } else {
                currentIndex = 0
              }
            }
          }
        } else {
          clearInterval(interval)
        }
      }, intervalDurationMs)
    }
    return () => {
      clearInterval(interval)
    }
  }, [props.isPlay])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 29 793 288"
      aria-label="Map of Indonesia"
    >
      {props.map.map(item => {
        return (
          <ProvincePath
            key={item.id}
            id={item.id}
            name={item.name}
            d={item.path}
            $isSelected={item.id === props.activeProvince}
            onClick={() => {
              props.setActiveProvince(item.id)
              props.setIsPlay(false)
            }}
            role="button"
          />
        )
      })}
    </svg>
  )
}

const ProvincePath = styled.path`
  fill: ${e =>
    e.$isSelected ? "rgb(var(--green))" : "rgb(var(--light-blue-01))"};
  stroke: white;
  stroke-width: 0.5px;
  &:hover {
    fill: ${e =>
      e.$isSelected ? "rgb(var(--green))" : "rgb(var(--light-blue-02))"};
  }
`

export default SVGMap
