import React from "react"
import Fade from "react-reveal/Fade"

import DataList from "./DataList"
import ButtonProvinceSelector from "./ButtonProvinceSelector"

const DataCard = props => {
  return (
    <div className="relative bg-white rounded-t-lg border border-solid border-gray-100 shadow-xl px-6 py-8 sm:w-3/4 sm:mx-auto md:w-full">
      <span className="block h-0.5 w-12 bg-bkpm-green rounded-full mb-2"></span>
      <h5 className=" text-bkpm-green mb-8">
        {props.mainTitle.labelRegional} {props.regionName}
      </h5>
      <ButtonProvinceSelector
        activeProvince={props.activeProvince}
        dataRegional={props.dataRegional}
        setActiveProvince={props.setActiveProvince}
        setIsPlay={props.setIsPlay}
      />
      <Fade bottom spy={props.activeProvince} distance="10px" cascade={false}>
        <DataList
          mainTitle={props.mainTitle}
          dataPropinsi={props.dataPropinsi}
        />
      </Fade>
    </div>
  )
}

export default DataCard
