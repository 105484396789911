import React from "react"

const ItemPeluang = props => {
  return (
    <div className="my-3 flex md:text-xs">
      <div className="w-2/12">
        <img src={props.icon} className="w-12 max-w-full" />
      </div>
      <div className="W-10/12 flex-grow md:pl-2">
        <div className="font-bold text-bkpm-dark-blue-01">{props.title}</div>
        <div className="text-gray-800">{props.value}</div>
      </div>
    </div>
  )
}

export default ItemPeluang
