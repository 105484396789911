import React, { useState } from "react"
import ResponsiveContainer from "components/UI/ResponsiveContainer"
import Fade from "react-reveal"
import { Swiper, SwiperSlide } from "swiper/react"
import Modal from "react-modal"
import IconIndustri from "components/images/ic-peluang-menu-industri.svg"
import IconSmelter from "components/images/ic-peluang-menu-smelter.svg"
import { Link } from "gatsby"
import { SRLWrapper } from "simple-react-lightbox"
import { IMGNoPhoto } from "components/images"

const PeluangProyek = ({
  mainTitle,
  dataSliderPeluangs,
  dataDeskripsiPeluangs,
  langCtx,
}) => {
  const [curIndex, setCurIndex] = useState(2)
  const [dataContact, setDataContact] = useState([])
  const [contactPeluangModal, setContactPeluangModal] = useState(false)

  return (
    <section
      className="bg-bkpm-light-blue-02"
      style={{ paddingBottom: "30px" }}
    >
      <ResponsiveContainer>
        <div className="pt-8">
          <h2 className="mb-8 text-bkpm-green">
            {mainTitle.labelPeluangInvestasi}{" "}
          </h2>
          {dataSliderPeluangs && dataSliderPeluangs.length !== 0 && (
            <Fade bottom>
              <Swiper
                className="umkm-slider same-height mb-4"
                spaceBetween={20}
                slidesPerView={7}
                autoplay={true}
                onSlideChange={e =>
                  setCurIndex(
                    curIndex < dataSliderPeluangs.length - 1 ? curIndex + 1 : 0
                  )
                }
                //onSwiper={(swiper) => console.log("swiper",swiper)}
                loop={true}
                breakpoints={{
                  100: {
                    slidesPerView: 1,
                  },
                  200: {
                    slidesPerView: 2,
                  },
                  380: {
                    slidesPerView: 2,
                  },
                  400: {
                    slidesPerView: 3,
                  },
                  480: {
                    slidesPerView: 4,
                  },
                  786: {
                    slidesPerView: 5,
                  },
                  1024: {
                    slidesPerView: 6,
                  },
                }}
              >
                {dataSliderPeluangs &&
                  dataSliderPeluangs.map((item, index) => {
                    var IconPeluang = null
                    if (index % 2 == 0) {
                      IconPeluang = IconIndustri
                    } else {
                      IconPeluang = IconSmelter
                    }
                    return (
                      <SwiperSlide key={index} virtualIndex={index}>
                        <div
                          className="text-center"
                          style={{
                            background:
                              curIndex == index ? "#82c6ef" : "transparent",
                            padding: 10,
                            borderRadius: 20,
                          }}
                        >
                          <button
                            onClick={() => setCurIndex(index)}
                            // onClick={() => {
                            //   setidPeluangSektor(item.idPeluangSektor)
                            // }}
                            className="w-16 h-16 group rounded-full bg-bkpm-dark-blue-01 flex items-center justify-center mx-auto mb-2 "
                          >
                            <center>
                              <img
                                src={item.icon}
                                className="w-10 transform -translate-y-px translate-x-px transition-all group-hover:transform group-hover:scale-125"
                                onError={e => {
                                  e.target.onerror = null
                                  e.target.src = IMGNoPhoto
                                }}
                              />
                            </center>
                          </button>
                          <h6 className="uppercase text-bkpm-dark-blue-01 font-bold line-clamp-2 max-h-14 text-xs">
                            {item.namaSingkat}
                          </h6>
                        </div>
                      </SwiperSlide>
                    )
                  })}
              </Swiper>
            </Fade>
          )}

          {dataDeskripsiPeluangs &&
            dataDeskripsiPeluangs.map((item, index) => {
              if (index == curIndex) {
                return (
                  <div
                    className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4"
                    key={index}
                  >
                    <div className={"col-span-1"}>
                      {/* <SRLWrapper> */}
                      <a href={`${item.fileImage}`}>
                        <img
                          className="w-full rounded-xl"
                          style={{
                            width: "100%",
                            height: "250px",
                            backgroundSize: "cover",
                            objectFit: "cover",
                          }}
                          src={item.fileImage}
                          onError={e => {
                            e.target.onerror = null
                            e.target.src = IMGNoPhoto
                          }}
                        />
                      </a>
                      {/* </SRLWrapper> */}
                      <div
                        className="grid grid-cols-2"
                        style={{ paddingTop: 20, paddingBottom: 20 }}
                      >
                        <button
                          onClick={() => {
                            setContactPeluangModal(!contactPeluangModal)
                            setDataContact({
                              namaSingkat: item.namaSingkat,
                              namaSektor: item.namaSektor,
                              daerahProvinsi: item.daerahProvinsi,
                              daerahKabkot: item.daerahKabkot,
                              kontaks: item.kontaks,
                            })
                          }}
                          style={{ backgroundColor: "green" }}
                          className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                          type={"button"}
                        >
                          {item.labelKontak}
                        </button>

                        <button
                          style={{ backgroundColor: "red" }}
                          className="bg-red-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                          type={"button"}
                        >
                          <Link
                            to={`/peluang-investasi/detail/?id=${item.idPeluang}`}
                          >
                            {item.labelDetil}
                          </Link>
                        </button>
                      </div>
                    </div>
                    <div className="col-span-2 ...">
                      <h4
                        className={"py-2"}
                        style={{
                          color: "#053783",
                          fontWeight: "bold",
                          fontFamily: "Arial",
                          maxHeight: 60,
                          marginBottom: 10,
                          WebkitLineClamp: 2,
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {item.namaSingkat}
                      </h4>
                      <p style={{ fontWeight: "bold" }}>
                        {item.tahun}
                        <br /> {item.namaSektor}
                        <br /> {item.daerahKabkot}, {item.daerahProvinsi}
                      </p>
                      {/* <h6 className={"py-2"} style={{color:"gray",fontWeight:"bold",fontStyle:"italic",
                    maxHeight:60,
                    marginBottom:10,
                    WebkitLineClamp:2,
                    textOverflow:"ellipsis",
                    display:"-webkit-box",
                    WebkitBoxOrient:"vertical",
                    overflow:"hidden"
                    }}>{item.deskripsiSingkat}</h6> */}
                      <p
                        className={"pb-4"}
                        style={{
                          maxHeight: 100,
                          WebkitLineClamp: 4,
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                        }}
                      >
                        {item.deskripsi}
                      </p>

                      <div
                        className="grid sm:grid-cols-2 md:grid-cols-4 gap-4"
                        style={{ color: "#053783" }}
                      >
                        <div className={"text-center"}>
                          <h2 style={{ marginBottom: 0 }}>
                            {item.irrs.value}{" "}
                            <span style={{ fontSize: 15 }}>
                              {item.irrs.lablePct}
                            </span>
                          </h2>
                          <span>{item.irrs.labelTitle}</span>
                        </div>
                        <div className={"text-center"}>
                          <h2 style={{ marginBottom: 0 }}>
                            <span style={{ fontSize: 15 }}>
                              {item.npps.labelCurrency}
                            </span>{" "}
                            {item.npps.value}{" "}
                            <span style={{ fontSize: 15 }}>
                              {item.npps.labelCurrencyUnit}
                            </span>
                          </h2>
                          <span>{item.npps.labelTitle}</span>
                        </div>
                        <div className={"text-center"}>
                          <h2 style={{ marginBottom: 0 }}>
                            {item.pps.value}{" "}
                            <span style={{ fontSize: 15 }}>
                              {item.pps.labelyear}
                            </span>
                          </h2>
                          <span>{item.pps.labelTitle}</span>
                        </div>
                        <div className={"text-center"}>
                          <h2 style={{ marginBottom: 0 }}>
                            <span style={{ fontSize: 15 }}>
                              {item.ivs.labelCurrency}
                            </span>{" "}
                            {item.ivs.value}{" "}
                            <span style={{ fontSize: 15 }}>
                              {item.ivs.labelCurrencyUnit}
                            </span>
                          </h2>
                          <span>{item.ivs.labelTitle}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
            })}

          <Modal
            isOpen={contactPeluangModal}
            onRequestClose={() => setContactPeluangModal(!contactPeluangModal)}
            overlayClassName="inset-0 bg-opacity-90 bg-black z-50 fixed flex items-center justify-center "
            className="p-0 w-10/12"
            style={{ content: { maxWidth: "650px" } }}
          >
            <div className="bg-white rounded-lg p-8">
              <h5>{dataContact && dataContact.namaSingkat}</h5>
              <h5>{langCtx?.language == "id" ? `Kontak` : `Contact`} :</h5>
              {dataContact.kontaks &&
                dataContact.kontaks.map((item, index) => {
                  console.log(item)
                  return (
                    <p className="text-bkpm-dark-blue-01 pb-2">
                      <div>
                        {item.namaKontak
                          ? langCtx?.language == "id"
                            ? `Nama` + ` : ${item.namaKontak}`
                            : `Name` + ` : ${item.namaKontak}`
                          : null}
                      </div>
                      <div>
                        {item.jabatanKontak ? `${item.jabatanKontak}` : null}
                      </div>
                      <div>
                        {item.alamatKontak
                          ? langCtx?.language == "id"
                            ? `Alamat` + ` : ${item.alamatKontak}`
                            : `Address` + ` : ${item.alamatKontak}`
                          : null}
                      </div>
                      <div>
                        {item.urlWebKontak
                          ? langCtx?.language == "id"
                            ? `Website` + ` : ${item.urlWebKontak}`
                            : `Website` + ` : ${item.urlWebKontak}`
                          : null}
                      </div>
                      <div>
                        {item.emailKontak
                          ? langCtx?.language == "id"
                            ? `Email` + ` : ${item.emailKontak}`
                            : `Email` + ` : ${item.emailKontak}`
                          : null}
                      </div>
                      <div>
                        {item.tlpKontak
                          ? langCtx?.language == "id"
                            ? `Telepon` + ` : ${item.tlpKontak}`
                            : `Phone Number` + ` : ${item.tlpKontak}`
                          : null}
                      </div>
                    </p>
                  )
                })}
            </div>
          </Modal>
        </div>
      </ResponsiveContainer>
    </section>
  )
}

export default PeluangProyek
