import React, { useRef, useState, useEffect } from 'react'

import { FaChevronDown } from 'react-icons/fa'

const Accordion = (props) => {

    const accordionContent = useRef()
    const [accordionHeight, setAccordionHeight] = useState(null)

    useEffect(() => {
        if ( props.isOpen ) {
            setAccordionHeight( accordionContent.current.scrollHeight)
        } else {
            setAccordionHeight(0)
        }
    })
    
    return (
        
        <div className="mb-4">
            <div className="relative pr-12">
                <button onClick={ props.clickHandler } className ="font-bold text-bkpm-dark-blue-01 w-full text-left">{ props.title }</button>
                <div className="absolute right-3 top-1/2 transform -translate-y-1/2" onClick={ props.clickHandler } role="button"><FaChevronDown className="transition-transform" style={ accordionHeight ? { transform: 'rotate(180deg)' } :'' } /></div>
            </div>
            <div
                ref={ accordionContent }
                style={ { height: accordionHeight } }
                className="overflow-hidden transition-all"
            >
                <div className="py-2">
                    { props.children }
                </div>
            </div>
        </div>
    )
}

export default Accordion