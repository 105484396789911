import React, { useState } from "react"
import Modal from "react-modal"

import ArrowButton from "components/UI/ArrowButton"

Modal.setAppElement("#___gatsby")

const ButtonContact = ({ mainTitle, mapData }) => {
  const [contactModalIsOpen, setContactModalIsOpen] = useState(false)
  const openModal = () => setContactModalIsOpen(true)
  const closeModal = () => setContactModalIsOpen(false)

  return (
    <>
      <Modal
        isOpen={contactModalIsOpen}
        onRequestClose={closeModal}
        overlayClassName="inset-0 bg-opacity-90 bg-black z-50 fixed flex items-center justify-center "
        className="p-0 w-10/12"
        style={{ content: { maxWidth: "650px" } }}
      >
        {mapData?.kantorDpmtsp.length > 0 ? (
          <div className="bg-white rounded-lg p-8">
            <h5 className="text-bkpm-dark-blue-01">
              {mapData?.kantorDpmtsp[0].labelTitleKantorPtsp} Provinsi{" "}
              {mapData?.kantorDpmtsp[0].value}
            </h5>

            <p className="text-bkpm-dark-blue-01">
              {mapData?.kantorDpmtsp[1].labelTitleAlamatKantorPtsp} :{" "}
              {mapData?.kantorDpmtsp[1].value}
              <br />
              {mapData?.kantorDpmtsp[2].labelTitleTlpKantorPtsp} :{" "}
              {mapData?.kantorDpmtsp[2].value}
              <br />
              {mapData?.kantorDpmtsp[3].labelTitleEmailKantorPtsp} :{" "}
              {mapData?.kantorDpmtsp[3].value}
            </p>
          </div>
        ) : (
          <div className="bg-white rounded-lg p-8">
            {/* Maaf, Data tidak ditemukan */}
          </div>
        )}
      </Modal>
      <div className="mb-8">
        <ArrowButton
          buttonStyle="expanded"
          colorVar="red"
          buttonClick={openModal}
        >
          {mainTitle.labelBtnDpmptsp}
        </ArrowButton>
      </div>
    </>
  )
}

export default ButtonContact
