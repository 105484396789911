import React from "react"

const ButtonProvince = props => {
  return (
    <button
      key={props.id}
      onClick={props.clickHandler}
      className="block font-medium leading-relaxed text-xs text-left hover:text-bkpm-green"
    >
      {props.name}
    </button>
  )
}

export default ButtonProvince
