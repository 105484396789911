import React, { useContext } from "react"
import ArrowButton from "components/UI/ArrowButton"
import ResponsiveContainer from "components/UI/ResponsiveContainer"
import LanguageContext from "components/ctx/language-context"
import { navigate } from "gatsby"

const Ikn = ({ data }) => {
  const langCtx = useContext(LanguageContext)
  return (
    <section className="bg-white my-5 py-5">
      <ResponsiveContainer>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="md:col-span-2">
            <h3 className="text-bkpm-dark-blue-01 font-bold">{data?.judul}</h3>
            <div
              className="text-md pb-5 md:pb-40"
              dangerouslySetInnerHTML={{
                __html:
                  data?.deskripsi ||
                  "Ibu Kota Negara akan dibangun untuk mencapai target Indonesia sebagai negara maju, sesuai Visi Indonesia 2045. Dibangun dengan identitas nasional, IKN akan mengubah orientasi pembangunan menjadi Indonesia-sentris, serta mempercepat Transformasi Ekonomi Indonesia.",
              }}
            />
            <ArrowButton
              buttonStyle="expanded"
              colorVar="red"
              buttonClick={() => navigate(`/ikn`)}
            >
              {langCtx.language === "id" ? `Selengkapnya` : `More`}
            </ArrowButton>
          </div>
          <div className="md:col-span-1">
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-1">
                <div
                  className="shadow-custom p-5 rounded-lg justify-center items-center cursor-pointer"
                  onClick={() => navigate(`/ikn?goto=indikator`)}
                >
                  <center>
                    <img
                      src="https://user-images.githubusercontent.com/49223890/221772702-0460fca0-b7bd-4040-ab9e-5042ad0fda86.png"
                      alt="8-prinsip"
                      className="w-10 h-auto"
                    />
                  </center>
                  <p className="remove-mb text-bkpm-dark-blue-01 font-bold text-sm mt-5 text-center">
                    {langCtx.language === "id"
                      ? `Prinsip IKN`
                      : `IKN Principles`}
                  </p>
                </div>
              </div>
              <div className="col-span-1">
                <div
                  className="shadow-custom p-5 rounded-lg justify-center items-center cursor-pointer"
                  onClick={() => navigate(`/ikn?goto=indikator`)}
                >
                  <center>
                    <img
                      src="https://user-images.githubusercontent.com/49223890/221772698-faaad923-bf1f-432a-a970-de0851bb17d2.png"
                      alt="visi-ikn"
                      className="w-10 h-auto"
                    />
                  </center>
                  <p className="remove-mb text-bkpm-dark-blue-01 font-bold text-sm mt-5 text-center">
                    {langCtx.language === "id" ? `Visi IKN` : `IKN Vision`}
                  </p>
                </div>
              </div>
              <div className="col-span-1">
                <div
                  className="shadow-custom p-5 rounded-lg justify-center items-center cursor-pointer"
                  onClick={() => navigate(`/ikn?goto=peluang-ikn`)}
                >
                  <center>
                    <img
                      src="https://user-images.githubusercontent.com/49223890/221772696-c8aee594-a257-49e3-80ca-bb97301d086c.png"
                      alt="peluang-ikn"
                      className="w-10 h-auto"
                    />
                  </center>
                  <p className="remove-mb text-bkpm-dark-blue-01 font-bold text-sm mt-5 text-center">
                    {langCtx.language === "id"
                      ? `Peluang IKN`
                      : `IKN Opportunities`}
                  </p>
                </div>
              </div>
              <div className="col-span-3">
                <div
                  className="shadow-custom p-5 rounded-lg flex justify-center items-center gap-6 cursor-pointer"
                  onClick={() => navigate(`/ikn?goto=map`)}
                >
                  <img
                    src="https://user-images.githubusercontent.com/49223890/221772695-9473978b-f59f-413d-8cbb-bc0e635bd2a9.png"
                    alt="wilayah-ikn"
                    className="w-20 h-auto"
                  />
                  <p className="remove-mb text-bkpm-dark-blue-01 font-bold text-sm ">
                    {langCtx.language === "id"
                      ? `Wilayah IKN berada di Kalimantan Timur yang merupakan superhub Indonesia`
                      : `The IKN region is in East Kalimantan which is Indonesia's superhub`}
                  </p>
                </div>
              </div>
              <div className="col-span-3">
                <div
                  className="shadow-custom p-5 rounded-lg flex justify-center items-center gap-6 cursor-pointer"
                  onClick={() => navigate(`/ikn?goto=peraturan`)}
                >
                  <img
                    src="https://user-images.githubusercontent.com/49223890/221772691-316d82ba-eddf-4120-a3d7-2c8b557863ef.png"
                    alt="wilayah-ikn"
                    className="w-20 h-auto"
                  />
                  <p className="remove-mb text-bkpm-dark-blue-01 font-bold text-sm ">
                    {langCtx.language === "id"
                      ? `Pembentukan IKN berdasarkan Undang-Undang No. 3 Tahun 2022 Tentang Ibu Kota Negara`
                      : `The formation of IKN is based on Law no. 3 of 2022 concerning the National Capital`}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ResponsiveContainer>
    </section>
  )
}

export default Ikn
