import React, { useContext, useEffect, useState } from "react"
import ResponsiveContainer from "components/UI/ResponsiveContainer"
import PartnerIcon from "./PartnerIcon"
import { withPrefix } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
// import "./style.css"
import axios from "axios"
import LanguageContext from "components/ctx/language-context"
import { Autoplay } from "swiper"

const Partner = ({ titlePartner, isIkn }) => {
  const [dataPartner, setDataPartner] = useState(null)
  const langCtx = useContext(LanguageContext)
  useEffect(async () => {
    const url =
      process.env.APIURLV2 +
      `api/Webs/GetLinkTerkait?page=${isIkn ? "ikn" : null}`
    const result = await axios.get(url, {
      headers: {
        xLanguange: langCtx.language,
      },
    })
    if (result.data.isSuccess) setDataPartner([...result.data.data])
  }, [])

  return (
    <section className="py-12">
      <ResponsiveContainer>
        <h4 className="text-bkpm-dark-blue-01 mb-8">{titlePartner}</h4>
        <Swiper
          slidesPerView={5.3}
          autoplay={true}
          speed={5000}
          modules={[Autoplay]}
          breakpoints={{
            480: {
              slidesPerView: 2.3,
            },
            786: {
              slidesPerView: 2.3,
            },
            980: {
              slidesPerView: 5.3,
            },
          }}
        >
          {dataPartner?.map((v, i) => {
            return (
              <SwiperSlide key={i} className="swiper-slide-partner">
                <PartnerIcon
                  to={v.urlWeb}
                  image={v.fileLogo}
                  label=""
                  title={v.nama}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </ResponsiveContainer>
    </section>
  )
}

export default Partner
