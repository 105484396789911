import React, { useContext, useState, useEffect } from "react"

import ResponsiveContainer from "components/UI/ResponsiveContainer"
import dataIndonesia from "components/store/dataIndonesia"
import dataIndonesiaEn from "components/store/dataIndonesiaEn"
import LanguageContext from "components/ctx/language-context"

import DataCard from "./DataCard"
import SVGMap from "./SVGMap"
import SektorUnggulan from "./SektorUnggulan"

const MapData = ({ mainTitle, dataRegional, dataSektorDaerah = [] }) => {
  const langCtx = useContext(LanguageContext)

  const [activeProvinceID, setActiveProvinceID] = useState(31)
  const [activeProvince, setActiveProvince] = useState("")
  const [regionName, setRegionName] = useState("")
  const [dataPropinsi, setDataPropinsi] = useState(null)
  const [mapData, setMapData] = useState(null)
  const [dataSekUng, setDataSekUng] = useState(null)
  const [isPlay, setIsPlay] = useState(true)

  const updateProvince = id => {
    setActiveProvinceID(id)
  }
  useEffect(() => {
    const dataWilayahs =
      langCtx.language === "id" ? dataIndonesia : dataIndonesiaEn
    const activeProvinceItem = dataWilayahs.find(
      item => item.id === activeProvinceID
    )
    setActiveProvince(activeProvinceItem.name)
    setRegionName(activeProvinceItem.region)
    const d = dataRegional.mapData.filter(
      item => item.id === activeProvinceItem.id
    )

    if (d.length !== 0) {
      setMapData(d[0])
      setDataPropinsi([...d[0].data])
    }

    if (dataSektorDaerah !== null) {
      const dummySekUng = dataSektorDaerah?.filter(
        item => item.idWil === activeProvinceItem.id
      )
      setDataSekUng(dummySekUng.length != 0 && dummySekUng[0])
    }
  }, [activeProvinceID])

  useEffect(() => {
    const dataWilayahs =
      langCtx.language === "id" ? dataIndonesia : dataIndonesiaEn
    const activeProvinceItem = dataWilayahs.find(
      item => item.id === activeProvinceID
    )
    if (dataSektorDaerah !== null) {
      const dummySekUng = dataSektorDaerah?.filter(
        item => item.idWil === activeProvinceItem.id
      )
      setDataSekUng(dummySekUng.length != 0 && dummySekUng[0])
    }
  }, [dataSektorDaerah])

  return (
    <>
      <section className="overflow-hidden">
        <ResponsiveContainer>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-8 items-center">
            <div className="md:col-span-2">
              <DataCard
                dataRegional={dataRegional}
                mapData={mapData}
                regionName={regionName}
                activeProvince={activeProvince}
                setActiveProvince={updateProvince}
                setIsPlay={setIsPlay}
                dataPropinsi={dataPropinsi}
                mainTitle={mainTitle}
              />
            </div>
            <div className="hidden md:block md:col-span-3">
              <SVGMap
                map={
                  langCtx.language === "id" ? dataIndonesia : dataIndonesiaEn
                }
                className="map-indonesia"
                activeProvince={activeProvinceID}
                setActiveProvince={updateProvince}
                setIsPlay={setIsPlay}
                isPlay={isPlay}
              />
            </div>
          </div>
        </ResponsiveContainer>
        {/* {dataSekUng && dataSekUng?.dataSektor.length !== 0 && ( */}
        <SektorUnggulan
          activeProvince={activeProvince}
          dataSekUng={dataSekUng}
          setIsPlay={setIsPlay}
          mainTitle={mainTitle}
          mapData={mapData}
        />
        {/* )} */}
      </section>
    </>
  )
}

export default MapData
