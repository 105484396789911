import React, { useEffect, useState } from "react"
import { FaCaretDown } from "react-icons/fa"
import Modal from "react-modal"
import styled from "styled-components"

import ButtonProvince from "./ButtonProvince"

Modal.setAppElement("#___gatsby")

const ButtonProvinceSelector = props => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const openModal = () => setModalIsOpen(true)
  const closeModal = () => setModalIsOpen(false)

  const groupBy = (arr, criteria) => {
    const newObj = arr.reduce(function (acc, currentValue) {
      if (!acc[currentValue[criteria]]) {
        acc[currentValue[criteria]] = []
      }
      acc[currentValue[criteria]].push(currentValue)
      return acc
    }, {})
    return newObj
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        overlayClassName="inset-0 bg-opacity-90 bg-black z-50 fixed flex items-center justify-center "
        className="w-10/12 bg-white rounded-lg p-8 overflow-y-auto"
        style={{ content: { maxWidth: "650px", maxHeight: "80vh" } }}
      >
        <div className="h-full overflow-y-auto">
          {/* <h5 className="text-bkpm-dark-blue-01">Provinsi</h5> */}
          <TwoColumn>
            {props.dataRegional &&
              props.dataRegional.wilayahs.map((itemWilayah, index) => {
                return (
                  <div key={index} className="mb-8 text-left">
                    <h5 className="text-bkpm-dark-blue-01 mb-2">
                      {itemWilayah}
                    </h5>
                    {props.dataRegional.mapData
                      .filter(
                        item =>
                          item.region.toLowerCase() ===
                          itemWilayah.toLowerCase()
                      )
                      .map(item => (
                        <ButtonProvince
                          key={item.id}
                          id={item.id}
                          name={item.name}
                          clickHandler={() => {
                            props.setActiveProvince(item.id)
                            props.setIsPlay(false)
                            closeModal()
                          }}
                        />
                      ))}
                  </div>
                )
              })}
          </TwoColumn>
        </div>
      </Modal>
      <button
        className="inline-block mb-8 bg-gradient-to-b from-bkpm-dark-blue-01 from-bkpm-dark-blue-01 to-bkpm-dark-blue-02 text-white rounded-full px-6 py-2"
        onClick={openModal}
      >
        {props.activeProvince}&emsp;
        <FaCaretDown className="inline-block" />
      </button>
    </>
  )
}

const TwoColumn = styled.div`
  @media (min-width: 768px) {
    column-count: 3;
    column-gap: 4rem;
    * {
      break-inside: avoid;
    }
  }
`

export default ButtonProvinceSelector
